import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import ListItem from '@mui/material/ListItem';
import { getUserRoleInCase } from 'app/components/caseViews/formHelpers/shared';
import MomentAdapter from '@date-io/moment';
import CasesListItemDropMenu from './casesListComponents/CasesListItemDropMenu';
import CasesListItemText from './casesListComponents/CasesListItemText';

const dateLib = new MomentAdapter();

const useStyles = makeStyles(theme => ({
  root: {
    '&.or-board-list-item': {
      '& .plan-ready': {
        color: '#137613',
        fontWeight: 'bold',
        fontSize: '1.3rem'
      },
      '& .plan-not-ready': {
        fontStyle: 'italic',
        fontSize: '1.2rem'
      }
    },
    borderBottomWidth: 1,
    borderColor: theme.palette.grey[200],
    position: 'relative'
  },
  date: {
    marginTop: theme.spacing(),
    alignSelf: 'baseline'
  }
}));

export default function CasesListItem(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { nickName: userName } = useSelector(({ auth }) => auth.user.data);
  const { kase: c, tab, handleClick, handleShare, reorderCase, caseGroup, residentOptions } = props;
  const [placeInDay, setPlaceInDay] = useState(null);

  const residentOptionsForSite = residentOptions?.filter(r =>
    r.userSites.some(site => site.siteId === c.siteId)
  );

  const user = useSelector(({ auth }) => auth.user.data);
  const userRoleInCase = getUserRoleInCase(c, user);

  const handleDelete = async () => {
    await props.handleDelete(c);
  };

  const handleArchive = async () => {
    await props.handleArchive(c);
  };

  const handleSelectResident = async (kase, residentId) => {
    await props.handleSelectResident(kase, residentId);
  };

  const isYou = name => {
    return name === userName ? t('You') : name;
  };

  const caseStateLogstoWordle = kase => {
    return kase.standardProcedure ? '🟦' : kase.isReady ? '🟩' : '🟨';
  };

  const updatePlaceInDay = () => {
    const indexInGroup = caseGroup.indexOf(c);
    const currentCaseDate = dateLib.date(caseGroup[indexInGroup]?.caseDate).format('l');
    const nextInGroupDate = dateLib.date(caseGroup[indexInGroup + 1]?.caseDate).format('l');
    const previousInGroupDate = dateLib.date(caseGroup[indexInGroup - 1]?.caseDate).format('l');
    const isFirstInDay = indexInGroup === 0 || previousInGroupDate !== currentCaseDate;
    const isLastInDay =
      indexInGroup === caseGroup.length - 1 || nextInGroupDate !== currentCaseDate;
    if (isFirstInDay) {
      setPlaceInDay('first');
    }
    if (isLastInDay) {
      setPlaceInDay('last');
    }
    if (!isFirstInDay && !isLastInDay) {
      setPlaceInDay('middle');
    }
    if (isFirstInDay && isLastInDay) {
      setPlaceInDay('alone');
    }
  };

  useEffect(() => {
    if (caseGroup) {
      updatePlaceInDay();
    }
  }, [c.indexInDay]);

  useEffect(() => {
    if (placeInDay === 'last' && caseGroup.indexOf(c) !== caseGroup.length - 1) {
      updatePlaceInDay();
    }
  });

  return (
    <Fragment>
      <ListItem
        key={c.id}
        className={clsx(`${tab}-list-item`, classes.root)}
        button={Boolean(props.loadCaseById) && tab !== 'published'}
        onClick={tab !== 'published' ? () => handleClick(c, false, userRoleInCase) : null}
      >
        <CasesListItemDropMenu
          tab={tab}
          kase={c}
          userRoleInCase={userRoleInCase}
          handleClick={handleClick}
          handleShare={handleShare}
          handleDelete={handleDelete}
          reorderCase={reorderCase}
          placeInDay={placeInDay}
        />
        <CasesListItemText
          tab={tab}
          kase={c}
          handleArchive={handleArchive}
          isYou={isYou}
          caseStateLogstoWordle={caseStateLogstoWordle}
          userRoleInCase={userRoleInCase}
          residentOptions={residentOptionsForSite}
          handleSelectResidentCb={handleSelectResident}
        />
      </ListItem>
    </Fragment>
  );
}

import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Icon from '@mui/material/Icon';
import Fab from '@mui/material/Fab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import UsersDialog from 'app/components/adminViews/UsersDialog';
import Avatar from 'app/components/userAvatar/UserAvatar';
import i18n from 'app/services/i18n';
import DropMenu from 'app/components/dropMenu/DropMenu';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import toast from 'app/services/toastService/toast';
import ConfirmationDialog from 'app/components/confirmDialog/ConfirmDialog';
import auth0Service from 'app/services/auth0Service';
import apiService from 'app/services/apiService';
import { loadSites, loadUsers, submitNewUser, submitUserData } from 'app/store/data/adminSlice';
import { ROLES } from 'app/consts';

const useStyles = makeStyles(theme => ({
  table: {},
  fab: {
    position: 'fixed',
    zIndex: 99,
    bottom: theme.spacing(10),
    right: theme.spacing(3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.light
    }
  },
  filtersContainer: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #e0e0e0',
    borderBottomColor: theme.palette.grey[400],
    padding: '10px 20px',
    height: '90px'
  },
  filterSelect: {
    width: '200px',
    marginRight: '10px'
  }
}));

export default function Users(props) {
  const classes = useStyles();
  const { users, sites } = useSelector(({ data }) => data.admin);

  const [selectedFilterRole, setSelectedFilterRole] = useState('');
  const [selectedFilterSite, setSelectedFilterSite] = useState('');
  const [selectedFilterText, setSelectedFilterText] = useState('');
  const [selectedJoinedFrom, setSelectedJoinedFrom] = useState('');
  const [filteredUsers, setFilteredUsers] = useState(users);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isSaml } = useSelector(({ metaData }) => ({
    isSaml: metaData.enum.isSaml
  }));

  const [confirmation, setConfirmation] = useState({
    open: false,
    message: ''
  });

  const [openUser, setOpenUser] = useState(false);

  const getSites = useCallback(() => {
    dispatch(loadSites());
  }, [dispatch]);
  const getUsers = useCallback(() => {
    dispatch(loadUsers());
  }, [dispatch]);
  useEffect(() => {
    getSites();
    getUsers();
  }, [getSites, getUsers, openUser]);

  useEffect(() => {
    handleChangeFilter();
  }, [users, selectedFilterRole, selectedFilterSite, selectedFilterText, selectedJoinedFrom]);

  const handleClick = user => {
    if (user !== 'new') {
      setOpenUser(user);
    } else {
      setOpenUser({ id: 'new' });
    }
  };

  const handleClickInviteAgain = (user, restoreByChiefy) => {
    if (isSaml) {
      throw new Error('Cannot resend invite for SAML');
    }
    try {
      const ret = apiService.addUser(user.email, user.nickName, null, true, true, restoreByChiefy);
      if (!ret) {
        throw new Error('Failed to add user');
      }
      if (restoreByChiefy) {
        toast.success(`Requested restore for ${user.nickName}/${user.email}`);
      } else {
        toast.success(`Invitation sent to ${user.nickName} at ${user.email}`);
      }
    } catch (err) {
      console.log(err);
      toast.error(err.message);
      throw err;
    }
  };

  const handleClickNotify = user => {
    setConfirmation({
      open: true,
      title: t(`Notify ${user.nickName} (${user.hasPn ? 'Push' : 'Text'}):`),
      user
    });
  };

  const handleClickDuplicateUser = user => {
    setOpenUser({ ...user, id: 'new' });
  };

  const handleNotifyConfirm = async (confirm, text) => {
    if (confirm) {
      try {
        apiService.notify(confirmation.user.id, text);
        toast.success(`Notified ${confirmation.user.nickName}: ${text}`);
      } catch (err) {
        console.log(err);
        toast.error(err.message);
        throw err;
      }
    }

    setConfirmation({ open: false });
  };

  const handleChangeFilterRole = event => {
    setSelectedFilterRole(event.target.value);
  };

  const handleChangeFilterSite = event => {
    setSelectedFilterSite(event.target.value);
  };

  const handleChangeJoinedFromText = event => {
    setSelectedJoinedFrom(event.target.value);
  };

  const handleChangeFilterText = event => {
    setSelectedFilterText(event.target.value);
  };

  const handleChangeFilter = () => {
    let filtered = users;
    if (selectedFilterRole !== '') {
      filtered = filtered.filter(user => user.roles.some(role => role.role === selectedFilterRole));
    }
    if (selectedFilterSite !== '') {
      filtered = filtered.filter(user =>
        user.userSites.some(site => site.site.name === selectedFilterSite)
      );
    }
    if (selectedJoinedFrom !== '') {
      filtered = filtered.filter(u => new Date(u.createdAt) >= new Date(selectedJoinedFrom));
    }

    const search = (string, searchString) => {
      const terms = searchString
        .toLowerCase()
        .split(' ')
        .map(term => term.trim())
        .filter(term => term !== '');
      return terms.every(term => string?.toLowerCase().includes(term));
    };

    if (selectedFilterText !== '') {
      filtered = filtered.filter(user => {
        const searchString = selectedFilterText.toLowerCase();
        return (
          search(user.nickName, searchString) ||
          search(user.email, searchString) ||
          search(user.phone, searchString) ||
          search(user.firstName, searchString) ||
          search(user.lastName, searchString)
        );
      });
    }
    setFilteredUsers(filtered);
  };

  const clearFilter = () => {
    setSelectedFilterRole('');
    setSelectedFilterSite('');
    setSelectedFilterText('');
    setFilteredUsers(users);
  };

  const cols = [
    { key: 'avatar', label: `(${filteredUsers.length})`, component: u => <Avatar user={u} /> },
    { key: 'nickName', label: 'Display Name' },
    { key: 'email', label: 'Email' },
    { key: 'hasPn', label: 'Push' },
    { key: 'muteUntil', label: 'Muted' },
    { key: 'followAll', label: 'Follow All' },
    { key: 'createdAt', label: 'Joined' },
    { key: 'phone', label: 'Phone' },
    { key: 'firstName', label: 'First Name' },
    { key: 'lastName', label: 'Last Name' },
    {
      key: 'roles',
      label: 'Roles',
      component: u => {
        return [...u.roles.map(r => i18n.t(r.role))].join(', ');
      }
    }
  ];

  const getTableCell = (u, col) => {
    if (col.component) {
      return col.component(u);
    }

    switch (col.key) {
      case 'muteUntil': {
        const muted = new Date(u.muteUntil) > new Date();
        return muted ? '🔇' : '';
      }
      case 'hasPn':
        return u.hasPn ? '+' : '';
      case 'followAll':
        return u.followAll ? '+' : '';
      case 'createdAt':
        return new Date(u.createdAt).toLocaleDateString();
      default:
        return u[col.key];
    }
  };

  return (
    <Fragment>
      <div className={clsx('flex flex-col', classes.root)}>
        <div className={classes.filtersContainer}>
          <FormControl className="flex flex-row items-center">
            <FormLabel className="mr-20">Service:</FormLabel>
          </FormControl>
          <TextField
            select
            fullWidth
            onChange={e => handleChangeFilterSite(e)}
            value={selectedFilterSite}
            className={classes.filterSelect}
          >
            {sites.map(site => (
              <MenuItem key={site.name} value={site.name}>
                {t(site.name)}
              </MenuItem>
            ))}
          </TextField>
          <FormControl className="flex flex-row items-center">
            <FormLabel className="mr-20">Role:</FormLabel>
          </FormControl>
          <TextField
            select
            fullWidth
            onChange={e => handleChangeFilterRole(e)}
            value={selectedFilterRole}
            className={classes.filterSelect}
          >
            {Object.values(ROLES)
              .filter(role => role !== ROLES.CARE_TEAM)
              .sort((a, b) => t(a).localeCompare(t(b)))
              .map(role => (
                <MenuItem key={role} value={role}>
                  {t(role)}
                </MenuItem>
              ))}
          </TextField>

          <FormControl className="flex flex-row items-center">
            <FormLabel className="mr-20">Joined from (inc):</FormLabel>
          </FormControl>
          <TextField onChange={e => handleChangeJoinedFromText(e)} value={selectedJoinedFrom} />

          <FormControl className="flex flex-row items-center">
            <FormLabel className="mr-20">Search by text:</FormLabel>
          </FormControl>
          <TextField onChange={e => handleChangeFilterText(e)} value={selectedFilterText} />

          {(selectedFilterRole || selectedFilterSite || selectedFilterText) && (
            <Button className="ml-20" variant="outlined" size="small" onClick={() => clearFilter()}>
              Clear Filters
            </Button>
          )}
        </div>
        <TableContainer>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                {cols.map(col => (
                  <TableCell key={col.key}>{col.label}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map(u => (
                <TableRow key={u.id} onClick={() => handleClick(u)} hover>
                  {cols.map(col => {
                    return (
                      <TableCell key={col.key} className={u.isActive ? '' : 'text-gray-400'}>
                        {getTableCell(u, col)}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    <DropMenu
                      buttonProps={{
                        className: 'kebab-menu -mr-16 order-last'
                      }}
                    >
                      {!isSaml && (
                        <Button
                          title={t('invite_again_menu_item')}
                          aria-label={t('invite_again_menu_item')}
                          onClick={e => {
                            handleClickInviteAgain(u, false);
                          }}
                          className="px-16 hover:bg-transparent"
                          disableRipple
                          variant="text"
                        >
                          <Icon>email</Icon>
                          <Typography variant="inherit" className="ml-12">
                            {t('invite_again_menu_item')}
                          </Typography>
                        </Button>
                      )}
                      <Button
                        title={t('invite_again_restore_by_chiefy_menu_item')}
                        aria-label={t('invite_again_restore_by_chiefy_menu_item')}
                        onClick={e => {
                          handleClickInviteAgain(u, true);
                        }}
                        className="px-16 hover:bg-transparent"
                        disableRipple
                        variant="text"
                      >
                        <Icon>warning</Icon>
                        <Typography variant="inherit" className="ml-12">
                          {t('invite_again_restore_by_chiefy_menu_item')}
                        </Typography>
                      </Button>
                      <Button
                        title={t('notify')}
                        aria-label={t('notify')}
                        onClick={e => {
                          handleClickNotify(u);
                        }}
                        className="px-16 hover:bg-transparent"
                        disableRipple
                        variant="text"
                      >
                        <Icon>message</Icon>
                        <Typography variant="inherit" className="ml-12">
                          {t('notify')}
                        </Typography>
                      </Button>

                      <Button
                        title={t('duplicate_user')}
                        aria-label={t('duplicate_user')}
                        onClick={e => {
                          handleClickDuplicateUser(u);
                        }}
                        className="px-16 hover:bg-transparent"
                        disableRipple
                        variant="text"
                      >
                        <Icon>warning</Icon>
                        <Typography variant="inherit" className="ml-12">
                          {t('duplicate_user')}
                        </Typography>
                      </Button>
                    </DropMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Fab onClick={() => handleClick('new')} className={classes.fab} aria-label="add">
        <Icon>add</Icon>
      </Fab>
      <UsersDialog
        submitData={async (id, model) => {
          try {
            if (id === 'new') {
              await dispatch(submitNewUser(model));
            } else {
              await dispatch(submitUserData(id, model));
            }
            return true;
          } catch (err) {
            return false;
          }
        }}
        closeDialog={() => setOpenUser(false)}
        user={openUser}
        sites={sites}
      />
      {confirmation.user && (
        <ConfirmationDialog
          open={confirmation.open}
          onClose={handleNotifyConfirm}
          textFieldValue={t('notify_default_msg', {
            name: confirmation.user.nickName,
            tenant: auth0Service.audPrefix
          })}
          title={confirmation.title}
        />
      )}
    </Fragment>
  );
}
